
/**
 * 判断域名显示不同的样式和首页展示模块
 */
/**域名地址 */
const HREF = window.location.hostname;

/**域名全地址 */
const DOMIAN = window.location.origin;
// DOMIAN == "http://192.168.1.117:1995" || DOMIAN == "https://www.10086wgt.com"
/**是否启动联通样式 */
const gdlt = DOMIAN == "https://gdlt.ai-wgt.com" ||DOMIAN == "https://yongchuang.kimkong.cn" || DOMIAN == "https://10010.ai-wgt.com" || DOMIAN == "https://gdlt.kimkong.cn" ? true : false
const gdlt_10010 = DOMIAN == "https://10010.ai-wgt.com" || DOMIAN == "http://10010.wengaowuyou.com" ? true : false
// 索贝
const suobei = DOMIAN == "https://idatacs-zhjt.sobeylingyun.com" ? true : false

/**是否启动移动样式 */
const yidong =
    DOMIAN == "http://localhost:19951" ||
        DOMIAN == "https://10086.ai-wgt.com" ||
        DOMIAN == "https://10086hy.ai-wgt.com" ||
        DOMIAN == "http://www.wengaowuyou.com" ||
        DOMIAN == "https://www.wengaowuyou.com" ||
        DOMIAN == "https://test10086.wengaowuyou.com" ||
        DOMIAN == "https://wengaowuyou.com" ? true : false

/**是否启动直销 || DOMIAN == "https://www.ai-wgt.com"*/
const zhixiao = DOMIAN == "http://www.aiwj365.com" || DOMIAN == "http://192.168.0.110:8080" || DOMIAN == "http://www.zhuyijiaodui.com" ? true : false

const gansu = DOMIAN == "https://wengao.gansudaily.com.cn" ||
    DOMIAN == "https://gansudaily.ai-wgt.com" ||
    DOMIAN == "http://103.116.78.7:9302" ||
    DOMIAN == "http://gansudaily.wengaowuyou.com" ? true : false

const beianLingxi = DOMIAN == "https://www.10086wgt.com"
/**是否显示服务案例 */
const service = DOMIAN == "http://192.168.0.113:8081" || DOMIAN == "https://www.ai-wgt.com" || DOMIAN == "http://192.168.1.117:1995" ? true : false
const zhuyijiaodui = DOMIAN == "http://www.zhuyijiaodui.com"
// 是否在ai-wgt和联通显示ai灵感和内容差异对比
// const aiduibi = DOMIAN == "https://test10086.ai-wgt.com" || DOMIAN == "https://www.ai-wgt.com" || DOMIAN == "http://192.168.1.117:1995"||DOMIAN == "https://gdlt.ai-wgt.com" || DOMIAN == "https://10010.ai-wgt.com" || DOMIAN == "https://gdlt.kimkong.cn" ? true : false
const aiduibi = DOMIAN == "https://test10086.ai-wgt.com" || DOMIAN == "https://test10086.wengaowuyou.com" || DOMIAN == 'http://192.168.1.6:8081' ? true : false
export default {
    gdlt,
    service,
    gansu,
    yidong,
    zhixiao,
    suobei,
    beianLingxi,
    zhuyijiaodui,
    aiduibi,
    gdlt_10010
}