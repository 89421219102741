import {
    createApp
} from 'vue'
import App from '@/App.vue'
import Vuex from 'vuex'
import router from '@/router';
import {
    ElLoading,
    ElMessageBox
} from 'element-plus'
import {
    findDomainDetails,
    putLogin,
    loginOut
} from "@/api/login"
import cookie from '../utils/cookie';
const app = createApp(App)
app.use(Vuex)

export default new Vuex.Store({

    state: {
        userInfo: null, // 用户基本信息
        domain: null, // 域名基本信息
        host_url: window.location.origin,
        loading: null, // loading 加载，
        ueditorMsg: '', // 检测文本
        defaultActiv: '/proofreading/text',
        bangding: {}
    },

    mutations: {
        setUserInfo(state, key) {
            if (key == null || key == 'null') {
                this.dispatch('verifyLogin')
            } else {
                localStorage.setItem('USERINFO', key)
                if (JSON.parse(key).loginToken) {
                    // cookie.delCookie('loginToken');
                    cookie.setCookie('loginToken', JSON.parse(key).loginToken)
                }
                state.userInfo = JSON.parse(key);
            }
        },
        setBangding(state, key) {
            state.bangding = key

        },
        setDomain(state, key) {
            state.domain = key;
        },
        setLoading(state, key) {
            state.loading = key;
        },
        setUeditorMSG(state, key) {
            state.ueditorMsg = key;
        },
        setDefaultActiv(state, key) {
            state.defaultActiv = key;
        },
        /**设置 登录跳转页面*/
        setRouterPush(state, key) {
            console.log(key,'key');
            if (key == 1) {
                // 巡检页面
                router.push("/media/overView");
            } else if (key == 2) {
                // 内容校对页面
                router.push("/proofreading/text");
            } else if (key == 3) {
                // 内容校对和巡检
                router.push("/proofreading/text");
            } else if (key == 4) {
                // 常见工具
                router.push("/tool/pdf&word");
            } else if (key == 5) {
                // 网站巡检 常见工具
                router.push("/media/overView");
            } else if (key == 6) {
                router.push("/proofreading/text");
            } else if (key == 7) {
                router.push("/proofreading/text");
            }else if (key == 8) {
                router.push("/proofreading/inspiration");
            }else if (key == 9) {
                router.push("/media/overView");
            }else if (key == 10) {
                router.push("/proofreading/text");
            }else if (key == 11) {
                router.push("/tool/pdf&word");
            }else if (key == 12) {
                router.push("/proofreading/text");
            }else if (key == 13) {
                router.push("/media/overView");
            }else if (key == 14) {
                router.push("/proofreading/text");
            }else if (key == 15) {
                router.push("/proofreading/text");
            }


            
        }
    },
    actions: {
        /**判断跳登录页面 { commit, state }*/
        verifyLogin() {
            router.push('/index')
        },
        /**根据网址 获取基本信息 */
        getfindDomainDetailsFn({
            commit
        }) {
            findDomainDetails({
                http_url: this.state.host_url
                // 'https://www.ai-wgt.com'
            }).then(res => {
                commit('setDomain', res.domainNameResult)

                /**设置icon */
                var link =
                    document.querySelector("link[rel*='icon']") || document.createElement("link");
                link.type = "image/x-icon";
                link.rel = "shortcut icon";
                link.href = res.domainNameResult.domain_icon_url;
                document.getElementsByTagName("head")[0].appendChild(link);
            })
        },
        /**全局加载动画 */
        async openFullScreenFn({
            commit
        }, object) {
            let obj = object || {}
            let status = obj.status || false;
            let text = obj.text || '等待中';
            if (status) {
                this.state.loading = ElLoading.service({
                    lock: true,
                    text: text,
                    background: 'rgba(255, 255, 255, 0.32)',
                })
            } else {
                this.state.loading.close();
            }
        },
        /**ip插入 */
        putLoginFn({
            commit
        }, object) {
            putLogin(object)
        },
        /**退出登陆 */
        loginOutFn({
            commit
        }, text) {
            ElMessageBox.confirm(text == undefined ? '' : text + '您可以继续留在该页面，或者退出登录', '系统提示', {
                confirmButtonText: '退出登录',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                cookie.delCookie('loginToken');
                loginOut({
                    uid: this.state.userInfo.id
                }).then(res => {
                    cookie.delCookie('loginToken');
                    window.localStorage.clear();
                    this.dispatch('verifyLogin')
                })
            }).catch(() => {

            })

        }
    }

})